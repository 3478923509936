import { formatAmount, formatDate } from "@/utils/formatter";
import { LineItemRecord, LineItemWithRecords, ProductInfoMap } from "@/utils/types";
import { isFoundationFoods } from "@/utils/user";
import { Box, HStack, VStack, Icon, Collapse, Text, Button, Spacer, Kbd, Tooltip } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { HiExclamation, HiX } from "react-icons/hi";

export default function OrderActivity({
  list,
  productInfoData,
  expectedPrices,
  supplierId,
  setPos,
  onOpen,
  orderStatus,
  onBuyerNoteOpen,
  buyerNote,
}: {
  list: LineItemWithRecords[];
  productInfoData: ProductInfoMap;
  expectedPrices?: Record<string, string>;
  supplierId: string;
  setPos: React.Dispatch<React.SetStateAction<number[]>>;
  onOpen: () => void;
  orderStatus: string;
  onBuyerNoteOpen: any;
  buyerNote: string;
}) {
  const isFF = isFoundationFoods(supplierId);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (!list.length) return;

    if (productInfoData[list[0]?.productId] || productInfoData[list[1]?.productId]) {
      setHasLoaded(true);
    }
  }, [productInfoData, list, productInfoData[list[0]?.productId], productInfoData[list[1]?.productId]]);

  return (
    <Box
      display="flex"
      flexDir="column"
      borderLeftWidth={{ base: 0, lg: "1px" }}
      w="xs"
      h={{ base: "full", lg: "auto" }}
    >
      <HStack
        justifyContent="space-between"
        borderBottomWidth="thin"
        borderBottomColor="gray.100"
        py={{ base: 3, lg: "3" }}
        px={{ base: 0, lg: "4" }}
        mt={{ base: 6, lg: 0 }}
        color="gray.600"
        pos="sticky"
      >
        <Text fontSize="sm" fontWeight="medium">
          Order Activity
        </Text>
        <Spacer />
        <Text fontSize="sm" fontWeight="normal">
          {list.length} Products
        </Text>
        {buyerNote && (
          <Tooltip label="Hotkey: shift + n" borderRadius="full" placement="top">
            <Button
              value={"addBuyerNote"}
              fontSize="2xs"
              _hover={{ bgColor: "gray.50", color: "gray.800" }}
              _focus={{ bgColor: "gray.50", color: "gray.800" }}
              _checked={{ color: "gray.800" }}
              isDisabled={orderStatus !== "NeedsReview"}
              onClick={() => {
                onBuyerNoteOpen();
              }}
              size="xs"
            >
              View Note
            </Button>
          </Tooltip>
        )}
      </HStack>
      {list.length ? (
        <Box overflow="auto" flex="1">
          {list.map((item, index) => {
            const lastOrderDate = productInfoData[item.productId]?.lastOrderDate;
            const productPrice = productInfoData[item.productId]?.productPrice;
            const unitPrice = productInfoData[item.productId]?.unitPrice;
            const unit = productInfoData[item.productId]?.unit;
            const packageSize = productInfoData[item.productId]?.packageSize ?? 1;
            const slug = productInfoData[item.productId]?.slug;
            const quantity = isFF ? item.quantity * packageSize : item.quantity;
            const price = isFF ? unitPrice : productPrice;
            const expectedPrice = expectedPrices?.[item.productId];
            const expectedPriceMismatch = !!expectedPrice && parseFloat(expectedPrice).toFixed(2) !== price?.toFixed(2);

            return (
              <VStack
                key={`${item.productId}_${index}`}
                py="2"
                borderTopWidth={index === 0 ? "" : "thin"}
                borderTopColor="gray.100"
                px={{ base: "2", lg: "4" }}
                alignItems="start"
                spacing="1"
              >
                {hasLoaded && productInfoData[item.productId] === undefined && (
                  <HStack
                    bgColor="red.50"
                    fontSize="xs"
                    px="1"
                    borderRadius="md"
                    borderWidth="thin"
                    borderColor="red.300"
                    spacing="1"
                  >
                    <Icon as={HiExclamation} color="red.400" boxSize="3" />
                    <Text color="red.500" fontWeight="medium">
                      Item No Longer Exists
                    </Text>
                  </HStack>
                )}
                <HStack fontSize="xs" spacing="1">
                  <Text color={quantity > 0 ? "gray.800" : "gray.400"} fontWeight="medium">
                    {quantity}
                  </Text>
                  <Icon as={HiX} boxSize="2" color="gray.600" />
                  <Text
                    display="inline"
                    px="2"
                    fontWeight="medium"
                    color={quantity > 0 ? "gray.800" : "gray.600"}
                    borderWidth="thin"
                    borderRadius="full"
                    borderColor="primary.400"
                  >
                    {slug ?? item.productId}
                  </Text>
                  {isFF && (
                    <Text color={quantity > 0 ? "gray.600" : "gray.400"} fontWeight="medium" textTransform="lowercase">
                      {unit}
                    </Text>
                  )}
                  {quantity == 0 && (
                    <HStack
                      bgColor="orange.50"
                      fontSize="xs"
                      px="1"
                      borderRadius="md"
                      borderWidth="thin"
                      borderColor="orange.300"
                      spacing="1"
                      ml="2"
                    >
                      <Icon as={HiExclamation} color="orange.400" boxSize="3" />
                      <Text color="orange.500" fontWeight="medium">
                        Item Ignored
                      </Text>
                    </HStack>
                  )}
                </HStack>
                <HStack>
                  <Text fontWeight="medium" color={quantity > 0 ? "gray.800" : "gray.400"} fontSize="xs">
                    {item.description}
                  </Text>
                </HStack>
                {(item.lastOrderedDate || lastOrderDate) && (
                  <Text fontSize="2xs" color={quantity > 0 ? "gray.600" : "gray.400"}>
                    Last ordered on {formatDate(item.lastOrderedDate || lastOrderDate!)}
                  </Text>
                )}
                {productInfoData[item.productId] && (
                  <HStack>
                    {price && (
                      <HStack spacing="1">
                        <Text fontSize="2xs" color={quantity > 0 ? "gray.600" : "gray.400"}>
                          Unit price:
                        </Text>
                        <Text fontSize="2xs" color={quantity > 0 ? "gray.600" : "gray.400"}>
                          {formatAmount(price)}
                        </Text>
                        {isFF && (
                          <Text fontSize="2xs" color={quantity > 0 ? "gray.600" : "gray.400"} textTransform="lowercase">
                            / {unit}
                          </Text>
                        )}
                      </HStack>
                    )}
                    {expectedPriceMismatch && (
                      <HStack spacing="1">
                        <Text fontSize="2xs" color={quantity > 0 ? "red.500" : "red.300"}>
                          Requested price:
                        </Text>
                        <Text fontSize="2xs" color={quantity > 0 ? "red.500" : "red.300"}>
                          {formatAmount(parseFloat(expectedPrice))}
                        </Text>
                        {isFF && (
                          <Text fontSize="2xs" color={quantity > 0 ? "red.500" : "red.300"} textTransform="lowercase">
                            / {unit}
                          </Text>
                        )}
                      </HStack>
                    )}
                  </HStack>
                )}

                {productInfoData[item.productId] && (
                  <HStack fontSize="xs" color="gray.500" flexWrap="wrap" gap="1">
                    {item.records.map((t, idx) => {
                      const change = isFF ? t.change * packageSize : t.change;

                      const text = ["CreateOrder", "AddItem"].includes(t.action)
                        ? `+ ${change}` // ? `+ ${change} ${item.description} `
                        : t.action === "DeleteItem"
                          ? `- ${change}`
                          : `${change}`;
                      const action = ["CreateOrder", "AddItem"].includes(t.action)
                        ? "added"
                        : t.action === "DeleteItem"
                          ? "removed"
                          : "";
                      return (
                        <Text
                          fontSize="xs"
                          fontWeight="medium"
                          onClick={() => {
                            onOpen();
                            setPos([t.x, t.y]);
                          }}
                          cursor="pointer"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          w="10"
                          h="5"
                          textAlign="center"
                          _hover={{
                            bgColor: action === "added" ? "primary.400" : action === "removed" ? "red.400" : "blue.400",
                            color: "white",
                          }}
                          color={action === "added" ? "primary.500" : action === "removed" ? "red.500" : "blue.500"}
                          borderRadius="md"
                          borderWidth="thin"
                          borderColor={
                            action === "added" ? "primary.400" : action === "removed" ? "red.400" : "blue.400"
                          }
                          key={idx}
                        >
                          {text}
                        </Text>
                      );
                    })}
                  </HStack>
                )}
                <ShowMessage records={item.records} />
              </VStack>
            );
          })}
        </Box>
      ) : (
        <Text textAlign="center" mt="2" color="gray.600">
          No products
        </Text>
      )}
    </Box>
  );
}

const ShowMessage = ({ records }: { records: LineItemRecord[] }) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);

  return (
    <>
      <Collapse startingHeight={0} in={show}>
        {records.map((elem, index) => (
          <Text key={index} w="60">
            {elem.text}
          </Text>
        ))}
      </Collapse>
      {/* Note: make this into a tooltip */}
      {/* <Button size="sm" onClick={handleToggle} variant="ghost">
        {show ? "Hide original text" : "Show original text"}
      </Button> */}
    </>
  );
};
