import { useEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  Button,
  PopoverBody,
  PopoverContent,
  Alert,
  AlertIcon,
  PopoverCloseButton,
  Kbd,
  HStack,
  Divider,
  VStack,
  Spacer,
  PopoverHeader,
  Icon,
} from "@chakra-ui/react";
import { useHotkeys } from "react-hotkeys-hook";
import type { Message, ProductInfoMap, ProductOption } from "@/utils/types";
import { useSidebarContext } from "@/providers/sidebar-context";
import _ from "lodash";
import ProductChoice from "./product-choice";
import ShortcutGuide from "./shortcut-guide";
import { HiViewGridAdd } from "react-icons/hi";
import { motion } from "framer-motion";

export default function MessageEditor({
  messages,
  pos,
  orderStatus,
  setMessages,
  deselectItem,
  selectNextItem,
  selectPrevItem,
  removeItem,
  productInfoData,
}: {
  messages: Message[];
  pos: number[];
  orderStatus: string;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  selectNextItem: () => void;
  selectPrevItem: () => void;
  deselectItem: () => void;
  removeItem: (pos: number[]) => void;
  productInfoData: ProductInfoMap;
}) {
  const [x, y] = pos;
  const chunk = messages?.[x].chunks?.[y];
  const isNeedsReview = orderStatus == "NeedsReview";
  const { isOpen: isSidebarOpen } = useSidebarContext();
  const [tabIndex, setTabIndex] = useState(chunk?.isManual ? 1 : 0);
  const inputRef = useRef<HTMLInputElement>(null);
  const [entryProduct, setEntryProduct] = useState<ProductOption | null>();
  const [productQuantity, setProductQuantity] = useState(
    chunk?.options?.[chunk.selected ?? 0]?.quantity ?? chunk?.manual?.quantity ?? 0
  );
  const [isIgnored, setIsIgnored] = useState(false);

  useHotkeys("shift+i", () => handleIgnore(), { preventDefault: true });

  useHotkeys("mod+delete", () => removeItem(pos), {
    preventDefault: true,
  });
  useHotkeys("backspace", () => handleIgnore(), {
    preventDefault: true,
  });

  // toggle manual by checking if chunk.selected changes from (chunk.options.length - 1) to chunk.options.length or vice versa
  useEffect(() => {
    toggleManual();
  }, [chunk?.selected]);

  const toggleManual = () => {
    // chunk!.isManual = !chunk?.isManual;
    chunk!.isManual = chunk?.selected == chunk?.options?.length ? true : false;

    if (chunk?.selected == chunk?.options?.length) {
      handleEntryQuantityChange("", productQuantity);
    } else {
      handleSuggestionQuantityChange("", productQuantity);
    }

    if (!chunk?.isManual && chunk?.options?.length == 0) {
      chunk.selected = undefined;
    }
    setMessages((prevState) =>
      prevState.map((item, index) => {
        if (index === x) {
          return {
            ...item,
            chunks: item.chunks?.map((t, idx) => {
              if (idx === y) {
                return chunk!;
              }
              return t;
            }),
          };
        }
        return item;
      })
    );
  };

  const handleProductSuggestionChange = (index?: number) => {
    if (isNeedsReview) {
      chunk!.selected = index;
      setMessages((prevState) =>
        prevState.map((item, index) => {
          if (index === x) {
            return {
              ...item,
              chunks: item.chunks?.map((t, idx) => {
                if (idx === y) {
                  return chunk!;
                }
                return t;
              }),
            };
          }
          return item;
        })
      );
    }
  };
  const handleProductEntryChange = (product: ProductOption) => {
    chunk!.manual!.productId = product.id;
    chunk!.manual!.slug = product.slug;
    chunk!.manual!.description = product.name;
    chunk!.manual!.raw = product;
    setMessages((prevState) =>
      prevState.map((item, index) => {
        if (index === x) {
          return {
            ...item,
            chunks: item.chunks?.map((t, idx) => {
              if (idx === y) {
                return chunk!;
              }
              return t;
            }),
          };
        }
        return item;
      })
    );
    setEntryProduct(product);
  };
  const handleSuggestionQuantityChange = (strValue: string, numValue: number) => {
    const updatedQuantity = numValue;

    if (isNeedsReview) {
      setMessages((prevState) =>
        prevState.map((item, index) => {
          if (index === x) {
            return {
              ...item,
              chunks: item.chunks?.map((t, idx) => {
                if (idx === y) {
                  // update chunk options quantity
                  chunk?.options?.map((option) => {
                    option.quantity = updatedQuantity;
                  });
                  return chunk!;
                }
                return t;
              }),
            };
          }
          return item;
        })
      );
    }
    setProductQuantity(numValue);
  };
  const handleEntryQuantityChange = (strValue: string, numValue: number) => {
    if (chunk?.manual) {
      chunk!.manual!.quantity = numValue;
    }
    setMessages((prevState) =>
      prevState.map((item, index) => {
        if (index === x) {
          return {
            ...item,
            chunks: item.chunks?.map((t, idx) => {
              if (idx === y) {
                return chunk!;
              }
              return t;
            }),
          };
        }
        return item;
      })
    );
    setProductQuantity(numValue);
  };
  const handleIgnore = () => {
    if (!isNeedsReview) return;
    if (chunk?.isManual) {
      chunk!.manual = {};
      setMessages((prevState) =>
        prevState.map((item, index) => {
          if (index === x) {
            return {
              ...item,
              chunks: item.chunks?.map((t, idx) => {
                if (idx === y) {
                  return chunk!;
                }
                return t;
              }),
            };
          }
          return item;
        })
      );
    } else {
      handleProductSuggestionChange();
    }

    setIsIgnored(true);
  };
  if (!chunk) return;

  return (
    <>
      <PopoverContent
        bgColor="white"
        w={{ base: "full", lg: "21.5rem" }}
        h="calc(100vh - 8.1rem)"
        _focusVisible={{ outline: "none" }}
      >
        <Box
          pos="fixed"
          right="21.5rem"
          bottom="0"
          w="100vw"
          h="100vh"
          bgColor="rgba(0, 0, 0, 0.4)"
          zIndex={10}
          onClick={() => deselectItem()}
        />
        <PopoverHeader textAlign="center" display="flex" alignItems="center" gap="2">
          <Icon as={HiViewGridAdd} boxSize="4" p="0" />
          <Text display="inline">Suggestions</Text>
        </PopoverHeader>
        <PopoverCloseButton size="md" zIndex="9" mr="6" />
        <PopoverBody
          as={motion.div}
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 20, opacity: 0 }}
          // @ts-expect-error
          transition={{ duration: 0.3 }}
          display="flex"
          flexDir="column"
          justifyContent="space-between"
          h="full"
          w="20rem"
        >
          <ProductChoice
            isDisabled={!isNeedsReview}
            chunk={chunk}
            entryProduct={entryProduct}
            isIgnored={isIgnored}
            setIsIgnored={setIsIgnored}
            setSelectIndex={handleProductSuggestionChange}
            handleProductChange={handleProductEntryChange}
            handleQuantityChange={
              (strValue: string, numValue: number) => {
                handleEntryQuantityChange(strValue, numValue);
                handleSuggestionQuantityChange(strValue, numValue);
              }
              // chunk.selected == chunk.options?.length ? handleEntryQuantityChange : handleSuggestionQuantityChange
            }
            deselectItem={deselectItem}
            selectNextItem={selectNextItem}
            selectPrevItem={selectPrevItem}
            productInfoData={productInfoData}
          />

          {!isNeedsReview && (
            <Alert status="info" mb="3">
              <AlertIcon />
              This order has been {orderStatus?.toLowerCase()}.
            </Alert>
          )}
          <Spacer />

          <Text mx="3" fontSize="2xs" color="gray.600">
            * Last ordered date as of receiving this message
          </Text>
          <Divider bgColor="gray.100" w="full" my="4" />
          {isNeedsReview && (
            <VStack gap="2" justifyContent="center">
              <Button onClick={handleIgnore} variant="outline" size="xs" fontSize="2xs" borderRadius="full" px="4">
                <Kbd fontSize="2xs">shift</Kbd> + <Kbd fontSize="2xs">i</Kbd>
                &nbsp;Ignore this detection
              </Button>
              <HStack alignItems="center">
                <ShortcutGuide />
              </HStack>
            </VStack>
          )}
        </PopoverBody>
      </PopoverContent>
    </>
  );
}
