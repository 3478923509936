import {
  Alert,
  Box,
  Button,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
  useSteps,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  Spacer,
  AlertIcon,
  IconButton,
} from "@chakra-ui/react";
import { queryOrderDetailByOrderId } from "@/api/queries/order-detail";
import queryClient from "@/api/query-client";
import { OrderDetail, Customer, Message } from "@/utils/types";
import { getUserCompany } from "@/utils/user";
import { SET_ORDER_CUSTOMER, UPDATE_ORDER_CUSTOMER } from "@/api/mutations/order";
import { useMutation } from "@tanstack/react-query";
import graphQLClient from "@/api/graphql-client";
import { useEffect, useRef, useState } from "react";
import { HiSparkles, HiViewList } from "react-icons/hi";
import { Outlet, Params, useLoaderData, useNavigate, useLocation, useRevalidator } from "react-router-dom";
import { UpdatedOrder, useOrderUpdated } from "@/hooks/subscription.ts";
import { checkSubscriptionError } from "@/utils/subscription.ts";
import { useSidebarContext } from "@/providers/sidebar-context";
import SearchableDropdown from "@/components/layouts/searchable-dropdown";
import { FiEdit } from "react-icons/fi";
import ContactForm from "@/components/items/contact-form";
import { queryCustomersByCustomerIds } from "@/api/queries/customer";

export async function loader({ params }: { params: Params }) {
  const { orderId } = params as { orderId: string };
  return getOrderDetail(orderId);
}

export default function OrderPage() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const sParam = queryParams.get("s");

  const steps = [
    { title: `analyzer${sParam ? `?s=${sParam}` : ""}`, description: "Chat Annotations", icon: HiSparkles },
    // { title: "editor", description: "Line Items", icon: HiPencil },
    { title: `summary${sParam ? `?s=${sParam}` : ""}`, description: "Order Details", icon: HiViewList },
  ];

  const {
    company: { companyId },
  } = getUserCompany();
  const revalidator = useRevalidator();
  const { order: _order } = (useLoaderData() as { order: OrderDetail }) || {};
  const { id: orderId, status } = _order || {};
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const { isOpen: isSidebarOpen } = useSidebarContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isInputOpen, onOpen: onInputOpen, onClose: onInputClose } = useDisclosure(); // user name input

  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [customer, setCustomer] = useState<Customer | null>(null); // customerId, customerName
  const [participants, setParticipants] = useState(_order?.messages ? getParticipantInfo(_order?.messages) : []); // participant user
  const [isLoading, setIsLoading] = useState(false);
  const textRef = useRef<HTMLTextAreaElement | null>(null);
  const toast = useToast();
  const [index, setIndex] = useState(0);
  const [order, setOrder] = useState<typeof _order>(_order);

  // customer search
  const [searchText, setSearchText] = useState("");
  const [isPossibleCustomer, setIsPossibleCustomer] = useState(false);
  const hasNoCustomer = !order?.customerId;

  // contact form pages
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = participants.length;

  useEffect(() => {
    setOrder(_order);
    setCustomer(null);
    setParticipants(_order?.messages ? getParticipantInfo(_order?.messages) : []);
    setSearchText("");
  }, [_order]);

  useEffect(() => {
    if (pathname.indexOf("/analyzer") > -1) {
      setActiveStep(0);
    } else if (pathname.indexOf("/editor") > -1) {
      setActiveStep(1);
    } else if (pathname.indexOf("/summary") > -1) {
      setActiveStep(2);
    }
    // refresh search bar
    setIndex((prev) => prev + 1);
  }, [orderId, pathname]);

  useOrderUpdated(async ({ data: updatedOrder, errors }: { data?: UpdatedOrder; errors?: Error[] }) => {
    if (checkSubscriptionError(errors)) return;
    if (!updatedOrder) return;
    if (Number(updatedOrder.id) != orderId) return;
    revalidator.revalidate();
  });

  const nextStep = () => {
    const next = activeStep + 1;
    if (next > steps.length) return;
    setActiveStep(next);
    navigate(steps[next].title);
  };
  const prevStep = () => {
    const next = activeStep - 1;
    if (next < 0) return;
    setActiveStep(next);
    navigate(steps[next].title);
  };

  const handleConfirmCustomer = async ({ customerCompanyId }: { customerCompanyId?: string | null }) => {
    if (hasNoCustomer) {
      try {
        setIsLoading(true);
        await mutateSetOrderCustomer.mutateAsync({
          customerId: customer!.customerId,
          orderId,
          customerCompanyId: customerCompanyId && customerCompanyId !== "" ? customerCompanyId : null,
          isGrubAssist: false,
        });
        revalidator.revalidate();
        toast({
          title: "Order reprocessing",
          description: `You have successfully updated the customer ${customer?.customerName} for order ${orderId} reprocessing.`,
          status: "info",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {},
        });
        onClose();
      } catch (err) {
        console.log(err);
        toast({
          title: "Failed to reprocess order",
          description: `There has been an error reprocessing this order, try again later.`,
          status: "error",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {},
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        setIsLoading(true);
        await mutateUpdateOrderCustomer.mutateAsync({
          customerId: customer!.customerId,
          orderId,
        });
        revalidator.revalidate();
        toast({
          title: "Order updated!",
          description: `You have successfully updated the customer ${customer?.customerName} for order ${orderId}.`,
          status: "info",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {},
        });
        onClose();
      } catch (err) {
        console.log(err);
        toast({
          title: "Failed to cancel order",
          description: `There has been an error updating this order, try again later.`,
          status: "error",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {},
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleOrderUpdated =
    (setOrder: (value: any) => void) =>
    async ({ data: updatedOrder, errors }: { data?: UpdatedOrder; errors?: Error[] }) => {
      if (checkSubscriptionError(errors)) {
        return;
      }
      if (!updatedOrder) {
        return;
      }
      const { order: orderDetail } = await getOrderDetail(updatedOrder.id);
      setOrder(orderDetail);
    };

  const handleInputClose = ({ customerCompanyId }: { customerCompanyId?: string }) => {
    handleConfirmCustomer({ customerCompanyId });
    onInputClose();
  };

  useOrderUpdated(handleOrderUpdated(setOrder));

  const mutateSetOrderCustomer = useMutation<
    unknown,
    unknown,
    { customerId: string; orderId: number; customerCompanyId: string | null; isGrubAssist: boolean }
  >(async ({ customerId, orderId, customerCompanyId, isGrubAssist }) => {
    return await graphQLClient.request(SET_ORDER_CUSTOMER, {
      customerId,
      orderId,
      ...(customerCompanyId ? { customerCompanyId } : {}),
      isGrubAssist,
    });
  });

  const mutateUpdateOrderCustomer = useMutation<unknown, unknown, { customerId: string; orderId: number }>(
    async ({ customerId, orderId }) => {
      return await graphQLClient.request(UPDATE_ORDER_CUSTOMER, {
        customerId,
        orderId,
      });
    }
  );

  const handleNextPage = () => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  };

  return (
    <Box ml={{ base: 4, lg: 0 }} mr={{ base: 4, lg: 6 }} mt={{ base: 0, lg: hasNoCustomer ? 3 : 4 }}>
      <VStack alignItems="start">
        {hasNoCustomer && (
          <Alert status="warning" colorScheme="cyan" py="1" mb="2">
            <AlertIcon />
            {/* @ts-expect-error */}
            {order?.metaData?.possibleCustomers.length > 0 ? (
              <HStack>
                <Text>We have detected that the customer could be </Text>
                {/* @ts-expect-error */}
                {order?.metaData?.possibleCustomers.map((customer, index) => (
                  <Button
                    key={index}
                    onClick={() => {
                      setSearchText(customer?.customerName);
                      setIsPossibleCustomer(true);
                      setCustomer(customer);
                    }}
                    variant="link"
                    size="xs"
                    color="gray.800"
                    fontWeight="semibold"
                  >
                    {customer?.customerName}
                  </Button>
                ))}
              </HStack>
            ) : (
              <Text>No customer has been detected. Please specify which customer this order is for below.</Text>
            )}
          </Alert>
        )}
        <HStack
          justifyContent="space-between"
          flexDir="row"
          h={{ base: "10", lg: "18" }}
          spacing={{ base: "0", lg: "2" }}
          w="full"
          mb="4"
        >
          <VStack alignItems="start" gap="0" w="full">
            <HStack w="full">
              {hasNoCustomer ? (
                <VStack alignItems="start" gap="0" mt="4">
                  <HStack ml="2" minW="sm">
                    <SearchableDropdown
                      searchText={searchText}
                      setSearchText={setSearchText}
                      isPossibleCustomer={isPossibleCustomer}
                      setIsPossibleCustomer={setIsPossibleCustomer}
                      setCustomer={setCustomer}
                      ref={initialRef}
                      nextRef={textRef}
                      placeholder="Search for a customer"
                    />
                    {!participants || participants?.length == 0 ? (
                      <Button
                        w="36"
                        bgColor="cyan.200"
                        ml="1"
                        onClick={() => handleConfirmCustomer({ customerCompanyId: null })}
                        isDisabled={!customer}
                        isLoading={isLoading}
                        color="gray.800"
                        size="sm"
                        colorScheme="cyan"
                      >
                        Set Customer
                      </Button>
                    ) : (
                      <Popover
                        onClose={onInputClose}
                        isOpen={isInputOpen}
                        onOpen={() => {
                          onInputOpen();
                        }}
                        initialFocusRef={textRef}
                        placement="top"
                      >
                        <PopoverTrigger>
                          <Button
                            w="36"
                            bgColor="cyan.200"
                            ml="1"
                            onClick={onInputOpen}
                            isDisabled={!customer}
                            isLoading={isLoading}
                            color="gray.800"
                            size="sm"
                            colorScheme="cyan"
                          >
                            Set Customer
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverHeader fontWeight="medium" textAlign="center" isTruncated w="72">
                            Add {participants?.length} contact(s) to {customer?.customerName}
                          </PopoverHeader>
                          <PopoverCloseButton />
                          <PopoverBody px="4" w={["full", "20rem"]}>
                            <ContactForm
                              order={order}
                              customer={customer}
                              participant={participants?.[currentPage]}
                              textRef={textRef}
                              goNextPage={
                                currentPage === totalPages - 1
                                  ? ({ customerCompanyId }: { customerCompanyId?: string }) => {
                                      handleInputClose({ customerCompanyId });
                                      // handleConfirmCustomer();
                                    }
                                  : handleNextPage
                              }
                            />
                            {/* {participants.length > 1 && (
                                <HStack justifyContent="space-between" mt="4" mb="2">
                                  <Button onClick={handlePrevPage} isDisabled={currentPage === 0}>
                                    Previous
                                  </Button>
                                  <Button onClick={handleNextPage} isDisabled={currentPage === totalPages - 1}>
                                    Next
                                  </Button>
                                </HStack>
                              )} */}
                            <VStack justifyContent="end" my="2">
                              <Button
                                onClick={() => {
                                  handleConfirmCustomer({ customerCompanyId: null });
                                  onInputClose();
                                }}
                                w="full"
                                size="sm"
                              >
                                Continue without Adding a Contact
                              </Button>
                            </VStack>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    )}
                  </HStack>
                </VStack>
              ) : (
                <Text
                  as="span"
                  fontWeight="semibold"
                  mx="2"
                  textTransform="capitalize"
                  fontSize="md"
                  color={hasNoCustomer ? "gray.400" : order?.customerName ? "gray.800" : "gray.400"}
                  fontStyle={hasNoCustomer ? "italic" : "normal"}
                >
                  {order?.customerName?.toLowerCase()}
                </Text>
              )}
              {["Initialized", "NeedsReview"].includes(status) && !hasNoCustomer && (
                <IconButton
                  variant="unstyled"
                  size="md"
                  icon={<FiEdit />}
                  color="gray.400"
                  _hover={{ color: "gray.800" }}
                  aria-label="Edit"
                  onClick={onOpen}
                />
              )}
              <Spacer />
            </HStack>

            <DeliveryAddress order={order} />
          </VStack>
        </HStack>
      </VStack>

      <Outlet context={{ nextStep, prevStep }} />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "full", lg: "lg" }}
        initialFocusRef={initialRef}
        allowPinchZoom={false}
        finalFocusRef={finalRef}
        closeOnEsc={true}
      >
        <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
        <ModalContent minH={60}>
          <ModalHeader>Select a Customer</ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody>
            <VStack>
              <SearchableDropdown
                searchText={searchText}
                setSearchText={setSearchText}
                isPossibleCustomer={isPossibleCustomer}
                setIsPossibleCustomer={setIsPossibleCustomer}
                setCustomer={setCustomer}
                ref={initialRef}
                nextRef={textRef}
                placeholder="Search for a customer"
              />
            </VStack>
          </ModalBody>

          <ModalFooter justifyContent="flex-end">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="primary"
              ml="3"
              onClick={() => handleConfirmCustomer({ customerCompanyId: null })}
              isDisabled={!customer}
              isLoading={isLoading}
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

async function getOrderDetail(orderId: string) {
  const {
    company: { companyId },
  } = getUserCompany();

  const {
    order,
  }: {
    order: OrderDetail;
  } = (await queryClient.fetchQuery(queryOrderDetailByOrderId(getUserCompany().company.companyId, orderId))) ?? {};

  // @ts-expect-error
  if (order?.metaData?.possible_customer_matches) {
    // @ts-expect-error
    const customerIds = order.metaData.possible_customer_matches.join(",");
    const fetchCustomers = queryCustomersByCustomerIds(companyId, customerIds);
    const { customers = [] }: { customerCount: number; customers: Customer[] } =
      (await queryClient.fetchQuery(fetchCustomers)) ?? {};
    // @ts-expect-error
    order.metaData.possibleCustomers = customers;
  }

  return { order };
}

function DeliveryAddress(props: { order: any }) {
  const deliveryAddress = props.order?.deliveryAddress;
  return (
    deliveryAddress && (
      <Text fontWeight="normal" fontSize="xs" mx="2" color="gray.600">
        {[
          deliveryAddress?.line1,
          deliveryAddress?.line2,
          deliveryAddress?.city,
          deliveryAddress?.state,
          deliveryAddress?.zip,
          deliveryAddress?.country,
        ]
          .filter(Boolean)
          .join(", ")}
      </Text>
    )
  );
}

function getParticipantInfo(messages: Message[]) {
  let participants = [];

  for (const message of messages) {
    const user = message.participant?.user;
    const participant = message.participant;
    let info = null;
    if (user?.userId) {
      if (user.userPhone || user.userEmail) {
        info = {
          displayName: user.userName,
          phoneNumber: user.userPhone,
          emailAddress: user.userEmail,
          isUser: true,
        };
      }
    } else {
      if (participant?.phoneNumber || participant?.emailAddress) {
        info = {
          displayName: participant?.displayName,
          phoneNumber: participant?.phoneNumber,
          emailAddress: participant?.emailAddress,
          isUser: false,
        };
      }
    }
    if (info) participants.push(info);
  }

  return participants;
}
