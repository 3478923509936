import { formatDate } from "@/utils/formatter";
import { Chunk, ProductInfoMap, ProductOption } from "@/utils/types";
import {
  HStack,
  Icon,
  Text,
  VStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Box,
  Radio,
  Divider,
  Kbd,
} from "@chakra-ui/react";
import _ from "lodash";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { BsDot } from "react-icons/bs";
import { HiChevronDown, HiChevronUp, HiExclamation, HiX } from "react-icons/hi";
import ProductSelect from "./product-select";

export default function ProductChoice({
  chunk,
  isDisabled,
  entryProduct,
  isIgnored,
  setIsIgnored,
  handleQuantityChange,
  handleProductChange,
  setSelectIndex,
  deselectItem,
  selectNextItem,
  selectPrevItem,
  productInfoData,
}: {
  chunk: Chunk;
  isDisabled: boolean;
  entryProduct?: ProductOption | null;
  isIgnored: boolean;
  setIsIgnored: Dispatch<SetStateAction<boolean>>;
  setSelectIndex: (idx?: number) => void;
  handleProductChange: (product: ProductOption) => void;
  handleQuantityChange: (strValue: string, numberValue: number) => void;
  deselectItem: () => void;
  selectNextItem: () => void;
  selectPrevItem: () => void;
  productInfoData: ProductInfoMap;
}) {
  const selectIndex = chunk.selected ?? 0; // selectIndex == chunk.options?.length is entry, less is suggestion
  const [isCheckboxFocused, setIsCheckboxFocused] = useState(true);
  const [isQtyInputFocused, setIsQtyInputFocused] = useState(false);
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);

  const [qty, setQty] = useState(chunk.options?.[selectIndex ?? 0]?.quantity ?? chunk?.manual?.quantity ?? 0);
  const qtyInput = useRef(null);
  const searchInput = useRef(null);

  useEffect(() => {
    setQty(chunk.options?.[selectIndex ?? 0]?.quantity ?? chunk?.manual?.quantity ?? 0);
  }, [chunk]);

  useHotkeys("tab, right", () => selectNextItem(), { enableOnFormTags: ["input"], preventDefault: true });
  useHotkeys("shift+tab, left", () => selectPrevItem(), { enableOnFormTags: ["input"], preventDefault: true });
  useHotkeys("enter", () => selectNextItem(), { enableOnFormTags: ["input"], preventDefault: true });
  useHotkeys(
    "down",
    () => {
      if (qtyInput.current) {
        (qtyInput.current as any).blur();
      }

      if (searchInput.current) {
        (qtyInput.current as any).blur();
      }

      if (chunk?.options?.length) {
        const index = (selectIndex ?? 0) + 1;

        setSelectIndex(index > (chunk.options?.length ?? 0) ? 0 : index); // setSelectIndex(index > (chunk.options?.length ?? 0) - 1 ? 0 : index);
        if (index == chunk.options?.length) {
          setIsSearchInputFocused(true);
          setIsCheckboxFocused(false);
          (searchInput.current as any).focus();
        } else {
          setIsSearchInputFocused(false);
          setIsCheckboxFocused(true);
          (searchInput.current as any).blur();
        }
      }
    },
    { enableOnFormTags: ["input"], preventDefault: true }
  );
  useHotkeys(
    "up",
    () => {
      if (qtyInput.current) {
        (qtyInput.current as any).blur();
      }
      if (searchInput.current) {
        (searchInput.current as any).blur();
      }

      if (chunk?.options?.length) {
        const index = (selectIndex ?? 0) - 1;
        setSelectIndex(index < 0 ? chunk.options?.length ?? 0 : index); // setSelectIndex(index < 0 ? (chunk.options?.length ?? 0) - 1 : index);

        if (index == (chunk.options?.length ?? 0) || index == -1) {
          setIsSearchInputFocused(true);
          setIsCheckboxFocused(false);
          (searchInput.current as any).focus();
        } else {
          setIsSearchInputFocused(false);
          setIsCheckboxFocused(true);
          (searchInput.current as any).blur();
        }
      }
    },
    { enableOnFormTags: ["input"], preventDefault: true }
  );
  useHotkeys(
    "shift+q",
    () => {
      if (qtyInput.current) {
        (qtyInput.current as any).focus();
      }
      setIsQtyInputFocused(true);
      setIsCheckboxFocused(false);
      setIsSearchInputFocused(false);
    },
    { enableOnFormTags: ["input"], preventDefault: true }
  );

  useHotkeys(
    "shift+down",
    () => {
      if (chunk.options?.length && selectIndex == chunk.options?.length) {
        setIsSearchInputFocused(true);
      }
    },
    { preventDefault: true }
  );

  useHotkeys(
    "esc",
    () => {
      if (isQtyInputFocused) {
        setIsQtyInputFocused(false);
        setIsCheckboxFocused(true);
      } else if (isSearchInputFocused) {
        setIsSearchInputFocused(false);
        setIsCheckboxFocused(true);
      } else if (isCheckboxFocused) {
        deselectItem();
      }
    },
    { enableOnFormTags: ["input"], preventDefault: true }
  );
  useHotkeys(
    "enter",
    () => {
      if (isQtyInputFocused) {
        setIsQtyInputFocused(false);
        setIsCheckboxFocused(true);
      } else if (isSearchInputFocused) {
        setIsSearchInputFocused(false);
        setIsCheckboxFocused(true);
      }
    },
    { enableOnFormTags: ["input"], preventDefault: true }
  );

  useEffect(() => {
    if (entryProduct) {
      setIsCheckboxFocused(true);
      setIsSearchInputFocused(false);
    }
  }, [entryProduct]);

  useEffect(() => {
    if (chunk.options?.length && selectIndex == chunk.options?.length) {
      setIsSearchInputFocused(true);
      setIsCheckboxFocused(false);
      setIsQtyInputFocused(false);
    } else {
      setIsSearchInputFocused(false);
      setIsCheckboxFocused(true);
      setIsQtyInputFocused(false);
    }
  }, [selectIndex]);

  return (
    <VStack alignItems="start" spacing="0" h="full">
      <VStack alignItems="start">
        <HStack ml="1">
          <Text fontSize="2xs" textAlign="center" color="gray.600">
            Quantity&nbsp;<Kbd fontSize="2xs">shift</Kbd>&nbsp;+&nbsp;<Kbd fontSize="2xs">q</Kbd>
          </Text>
          <Box w="14" />
          <Text fontSize="2xs" textAlign="center" color="gray.600">
            Product
          </Text>
        </HStack>
        <HStack w="full">
          <NumberInput
            maxW={24}
            defaultValue={qty}
            value={qty}
            onChange={(valueString, valueNumber) => {
              if (valueNumber !== undefined && !isNaN(valueNumber)) {
                setQty(valueNumber);
                handleQuantityChange(valueString, valueNumber);
              } else {
                setQty(0);
                handleQuantityChange(valueString, 0);
              }
            }}
            isDisabled={isDisabled}
            size="sm"
            min={0}
          >
            <NumberInputField
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              ref={qtyInput}
            />
            <NumberInputStepper>
              <NumberIncrementStepper children={<HiChevronUp />} />
              <NumberDecrementStepper children={<HiChevronDown />} />
            </NumberInputStepper>
          </NumberInput>
          <Icon as={HiX} boxSize="3" color="gray.400" />

          <Text fontSize="2xs" isTruncated textAlign="center">
            {chunk.options?.length && selectIndex == chunk.options?.length
              ? chunk.manual?.slug
              : chunk.options?.[selectIndex ?? 0]?.slug ?? chunk.options?.[selectIndex ?? 0]?.productId}
          </Text>
        </HStack>
        {qty == 0 && (
          <HStack fontSize="xs" px="1" spacing="1">
            <Icon as={HiExclamation} color="orange.400" boxSize="3" />
            <Text color="orange.500" fontWeight="medium">
              This item will be ignored when quantity is 0
            </Text>
          </HStack>
        )}
      </VStack>

      <Divider bgColor="gray.100" w="full" my="3" />

      <Box alignItems="start" w="full" h="full">
        {chunk.options?.length == 0 ? (
          <Text textAlign="center" borderWidth="thin" py="4" mb="4" borderRadius="md" color="gray.600">
            We did not detect any products. Please manually enter an order item.
          </Text>
        ) : (
          <>
            {chunk.options
              ?.filter((item, index, self) => index === self.findIndex((t) => t.productId === item.productId))
              .map((item, index) => (
                <Radio
                  key={index}
                  py="1"
                  px="2"
                  _hover={{ bgColor: isDisabled ? "" : "gray.50" }}
                  isChecked={selectIndex === index && !isIgnored}
                  isDisabled={isDisabled}
                  onChange={() => {
                    setSelectIndex(index);
                    setIsIgnored(false);
                    setIsCheckboxFocused(true);
                    setIsSearchInputFocused(false);
                    setIsQtyInputFocused(false);
                  }}
                  onFocus={() => setIsCheckboxFocused(true)}
                  onBlur={() => setIsCheckboxFocused(false)}
                  isTruncated
                  w="full"
                  _focusVisible={{ outline: "none" }}
                  _focusWithin={{ outline: "none" }}
                  _focus={{ outline: "none" }}
                  outline="none"
                  outlineColor="transparent"
                >
                  <VStack
                    alignItems="start"
                    spacing="0"
                    _hover={{ bgColor: isDisabled ? "" : "gray.100" }}
                    bgColor={isCheckboxFocused && !isIgnored && selectIndex === index ? "gray.100" : ""}
                    p="2"
                    w="16rem"
                  >
                    {!productInfoData[item?.productId ?? ""] && index == 0 && (
                      <HStack
                        bgColor="red.50"
                        fontSize="xs"
                        px="1"
                        borderRadius="md"
                        borderWidth="thin"
                        borderColor="red.300"
                        spacing="1"
                      >
                        <Icon as={HiExclamation} color="red.400" boxSize="3" />
                        <Text color="red.500" fontWeight="medium">
                          Item No Longer Exists
                        </Text>
                      </HStack>
                    )}
                    <HStack spacing="1" w="full">
                      <Text fontSize="xs">{item.slug ?? item.productId}</Text>
                      <Icon as={BsDot} boxSize="2" color="gray.400" />
                      <Text fontSize="2xs" color="gray.800">
                        Last ordered{" "}
                        {item.lastOrderedDate ? `on ${formatDate(item.lastOrderedDate, "numeric")}` : `N/A`}
                      </Text>
                    </HStack>
                    <Text fontSize="xs" w="full">
                      {item.description}
                    </Text>
                  </VStack>
                </Radio>
              ))}
          </>
        )}
        <HStack mt="1">
          <Radio
            py="1"
            px="2"
            _hover={{ bgColor: isDisabled ? "" : "gray.50" }}
            isChecked={selectIndex === (chunk.options?.length ?? 0)}
            isDisabled={isDisabled}
            onChange={() => {
              setSelectIndex(chunk.options?.length);
              setIsSearchInputFocused(true);
            }}
            onFocus={() => setIsSearchInputFocused(true)}
            onBlur={() => setIsSearchInputFocused(false)}
            isTruncated
            w="auto"
            mr="-2"
            _focusVisible={{ outline: "none" }}
            _focusWithin={{ outline: "none" }}
            _focus={{ outline: "none" }}
            outline="none"
            outlineColor="transparent"
          ></Radio>
          <ProductSelect
            chunk={chunk}
            handleChange={handleProductChange}
            isDisabled={isDisabled}
            isFocused={isSearchInputFocused && selectIndex == chunk.options?.length}
            searchInput={searchInput}
            setSelectIndex={setSelectIndex}
            setIsSearchInputFocused={setIsSearchInputFocused}
          />
        </HStack>
      </Box>
    </VStack>
  );
}
