import { formatDate, formatTitle, getTimeDifference } from "@/utils/formatter";
import { Order } from "@/utils/types";
import { HStack, Icon, Badge, Text, VStack, Button } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { HiCheck, HiChevronDoubleRight, HiOutlineChatAlt, HiReply } from "react-icons/hi";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function OrderItem({
  order,
  isMenuOpen,
  itemRef,
  orderIndex,
  orderIndexFocused,

  setOrderIndexFocused,
}: {
  order: Order;
  isMenuOpen: boolean;
  itemRef: any;
  orderIndex: number;
  orderIndexFocused: number;
  setOrderIndexFocused: Dispatch<SetStateAction<number>>;
}) {
  const { orderId: _orderId } = useParams();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const tab = (pathname.match(/\/orders\/\d+\/([^/?]+)/) || [])[1] ?? "analyzer";
  const queryParams = new URLSearchParams(search);
  const sParam = queryParams.get("s");

  const {
    id: orderId,
    createdAt,
    customerId,
    customerName,
    updatedAt,
    orderStatus,
    firstMessage,
    lastMessageAt,
    messages,
  } = order;
  const hasNoCustomer = !customerId;

  const { icon, color, colorScheme } = useMemo(() => {
    switch (orderStatus) {
      case "Confirmed":
        return {
          icon: HiCheck,
          color: "primary.400",
          colorScheme: "primary",
        };
      case "NeedsReview":
        return {
          icon: HiReply,
          color: "blue.400",
          colorScheme: "blue",
        };
      case "Initialized":
        return {
          icon: HiChevronDoubleRight,
          color: "gray.400",
          colorScheme: "gray",
        };
      case "Pending":
        return {
          icon: HiChevronDoubleRight,
          color: "gray.400",
          colorScheme: "cyan",
        };
      default:
        return {
          icon: HiChevronDoubleRight,
          color: "gray.400",
          colorScheme: "gray",
        };
    }
  }, [orderStatus]);

  useEffect(() => {
    if (orderIndexFocused == orderIndex) {
      setOrderIndexFocused(orderIndex);
    }
  }, []);

  return (
    <HStack
      py="2"
      bgColor={orderId == _orderId ? "primary.100" : "white"}
      _hover={{ bgColor: `${orderId == _orderId ? "primary.100" : "gray.50"}` }}
      borderBottom="1px solid thin"
      borderColor="green.400"
      as={Button}
      h="full"
      onClick={() => {
        navigate(`/orders/${orderId}/${"analyzer"}${sParam ? `?s=${sParam}` : ""}`); // navigate(`/orders/${orderId}/${tab}${sParam ? `?s=${sParam}` : ""}`);
      }}
      ref={itemRef}
      _focusVisible={{ outline: "none" }}
    >
      <VStack alignItems="start" ml="2" mr="5" spacing="1">
        <HStack display={isMenuOpen ? "flex" : "none"}>
          <Icon as={icon} color={color} ml="2" />
          <Text color="gray.800" fontSize="xs" fontWeight="medium">
            #{orderId}
          </Text>
          <Text
            fontWeight="bold"
            h="fit-content"
            isTruncated
            w="36"
            textAlign="left"
            color={hasNoCustomer ? "cyan.500" : "gray.800"}
            fontStyle={hasNoCustomer ? "italic" : "normal"}
          >
            {hasNoCustomer ? "No customer detected" : formatTitle(customerName)}
          </Text>
          <Badge
            variant="outline"
            colorScheme={colorScheme}
            textTransform="capitalize"
            textAlign="center"
            fontWeight="semibold"
            borderRadius="full"
            fontSize="2xs"
            ml="2"
            w="24"
          >
            {formatTitle(orderStatus)}
          </Badge>
        </HStack>
        {!isMenuOpen && (
          <VStack ml="1">
            <Icon as={icon} color={color} />
            <Text color="gray.800" fontSize="xs" fontWeight="medium">
              #{orderId}
            </Text>
          </VStack>
        )}
        <HStack display={isMenuOpen ? "flex" : "none"}>
          <VStack alignItems="start" ml="2" spacing="0">
            <HStack>
              <Text color="gray.600" fontSize="xs">
                {formatDate(createdAt)}
              </Text>
              {lastMessageAt && (
                <Text color="gray.600" fontSize="xs">
                  Last updated {getTimeDifference(lastMessageAt)}
                </Text>
              )}
            </HStack>
            <Text color="gray.800" fontSize="xs" isTruncated w="60" textAlign="left">
              {firstMessage || "-"}
            </Text>
          </VStack>
          <HStack spacing="1" color="gray.600" ml="4">
            <Icon as={HiOutlineChatAlt} boxSize="4" />
            <Text>{messages?.length ?? 0}</Text>
          </HStack>
        </HStack>
      </VStack>
    </HStack>
  );
}
