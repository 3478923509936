import {
  Box,
  HStack,
  Avatar,
  Spacer,
  Text,
  PopoverTrigger,
  PopoverAnchor,
  Flex,
  VStack,
  Icon,
  IconButton,
  Divider,
  Spinner,
  Button,
  Select,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef, useEffect, Fragment, useState, Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { substr } from "runes2";
import _ from "lodash";
import { formatTimestamp, formatTitle, isTimeDifferenceGreaterThanOneHour } from "@/utils/formatter";
import { useWavesurfer } from "@wavesurfer/react";
import Timeline from "wavesurfer.js/dist/plugins/timeline.esm.js";
import { HiChevronDown, HiPause, HiPlay, HiX } from "react-icons/hi";
import type { Chunk, ChunkOption, LineItemWithRecords, Message, ProductInfoMap, Suggestion } from "@/utils/types";
import { FaRegFileAlt } from "react-icons/fa";
import { MdAutoAwesome, MdPerson } from "react-icons/md";

export default function MessageItem(props: {
  data: Message;
  index: number;
  pos: number[];
  productInfoData: ProductInfoMap;
  setChunkRefs: (index: number, list: (HTMLDivElement | null)[]) => void;
  setPos: React.Dispatch<React.SetStateAction<number[]>>;
  prevAuthor: string | null;
  prevTimestamp: string | null;
}) {
  const {
    id,
    author,
    authorType,
    createdTs,
    aiResp,
    chunks,
    participant,
    isContext,
    type,
    mediaList,
    creationStatus,
    creationError,
  } = props.data;

  const { prevAuthor, prevTimestamp, productInfoData } = props;
  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);
  const [activeCaptionIndex, setActiveCaptionIndex] = useState<number | null>(null);
  const audioMedia = mediaList?.find((elem) => elem.type === "Audio");
  const ediMedia = mediaList?.filter((elem) => elem.type === "EDI");
  const hasEdiMedia = !!ediMedia?.length;
  useEffect(() => {
    props.setChunkRefs(props.index, itemsRef.current);
  }, [chunks?.length]);
  const isSelf = authorType == "Human";
  const isPending = creationStatus == "Pending";

  return (
    <VStack key={props.index} alignItems={isSelf ? "end" : "start"}>
      {isTimeDifferenceGreaterThanOneHour(prevTimestamp, createdTs) && (
        <Text color="gray.500" fontSize="xs" fontWeight="medium" align="center" mt="2" w="full">
          {formatTimestamp(createdTs)}
        </Text>
      )}
      {author + authorType !== prevAuthor || isTimeDifferenceGreaterThanOneHour(prevTimestamp, createdTs) ? (
        <HStack
          mt="2"
          spacing="2"
          onMouseUp={(e) => {
            e.stopPropagation();
          }}
        >
          <Avatar
            size="2xs"
            name={participant?.displayName || author}
            src={participant?.type === "BUTTER" ? "/icon.png" : ""}
            bgColor={participant?.type === "BUYER" ? "blue.100" : "gray.100"}
            color={participant?.type === "BUYER" ? "gray.800" : "gray.800"}
          />
          <Text color="gray.600" fontSize={"xs"} align={isSelf ? "right" : "left"} fontWeight="medium">
            {formatTitle(participant?.displayName) || "-"}
          </Text>
          <Text color="gray.400" fontSize={"xs"} align={isSelf ? "right" : "left"}>
            {formatTitle(author)}
          </Text>
          <Icon as={authorType == "Human" ? MdPerson : MdAutoAwesome} color="gray.600" />
        </HStack>
      ) : (
        <></>
      )}

      <MessageFailedCase creationStatus={creationStatus} creationError={creationError} />

      {(creationStatus === "Success" || creationStatus == "Pending") && (
        <HStack alignItems="end" w="full">
          {isSelf && <Spacer />}
          <Box
            p="2"
            my={type == "Email" ? 1 : 0}
            borderWidth="thin"
            borderRadius="lg"
            borderTopLeftRadius={isSelf ? "lg" : "0"}
            borderTopRightRadius={isSelf ? "0" : "lg"}
            borderColor={
              type == "Email" || "Manual"
                ? { BUYER: "blue.100", SELLER: "gray.100", SYSTEM: "gray.100" }[participant?.type || "BUYER"]
                : "white"
            }
            bgColor={
              type == "SMS" || "Manual"
                ? { BUYER: "blue.50", SELLER: "gray.50", SYSTEM: "gray.50" }[participant?.type || "BUYER"]
                : "white"
            }
            color={isContext ? "gray.800" : "gray.800"}
            wordBreak="break-word"
            fontSize="md"
            userSelect={isContext ? "none" : "text"}
            title={`${formatTimestamp(createdTs)} via ${type}`}
            minWidth={audioMedia && isPending ? 300 : undefined}
          >
            {isPending && (
              <HStack>
                <Spinner thickness="2px" speed="0.65s" emptyColor="gray.200" color="primary.400" size="xs" />
                <Text fontSize="2xs" color="gray.600">
                  Message Pending
                </Text>
              </HStack>
            )}
            {audioMedia && (
              <>
                <WavePlayer
                  key={id}
                  trackUrl={`${audioMedia.url}?id=${id}`}
                  segments={audioMedia.segments!}
                  setActiveCaptionIndex={setActiveCaptionIndex}
                />
                <Divider mb="2" />
              </>
            )}
            {!chunks && <Text>{props.data.body}</Text>}
            {chunks?.map((chunk, cidx) => {
              const hasOptions = !!chunk.options;
              const [x, y] = props.pos;
              const isSelected = x === props.index && y === cidx;
              return (
                <Fragment key={cidx}>
                  <PopoverTrigger>
                    <Box
                      as="span"
                      ref={(el: HTMLDivElement | null) => (itemsRef.current[cidx] = el)}
                      position="relative"
                      data-start={chunk.textRange[0]}
                      data-end={chunk.textRange[1]}
                      data-index={props.index}
                      key={cidx}
                      className={hasOptions ? "chunk-slice" : ""}
                      cursor={hasOptions ? "pointer" : ""}
                      borderRadius={hasOptions ? 4 : 0}
                      p={hasOptions ? "0" : "0"}
                      zIndex={isSelected ? 100 : 0}
                      onClick={() => {
                        if (hasOptions) props.setPos([props.index, cidx]);
                      }}
                      onMouseUp={(e: { stopPropagation: () => void }) => {
                        if (hasOptions) e.stopPropagation();
                      }}
                    >
                      {aiResp && cidx == 0 && (
                        <Text mb="4" color="gray.600">
                          {aiResp}
                        </Text>
                      )}
                      {
                        <Text
                          display="inline"
                          bgColor={
                            hasOptions && !isActive(chunk)
                              ? "#ededed"
                              : isSelected
                                ? "white"
                                : hasOptions
                                  ? "primary.100"
                                  : ""
                          }
                          textDecor={chunk.segmentIndex === activeCaptionIndex ? "underline dashed" : ""}
                          data-start={chunk.textRange[0]}
                          data-end={chunk.textRange[1]}
                          data-index={props.index}
                          whiteSpace="pre-wrap"
                        >
                          {chunk.text}
                        </Text>
                      }
                      <Text display="inline" className="chunk-slice"></Text>

                      {/* Note: popover positioned to the highlighted text */}
                      {/* {isSelected && (
                        <PopoverAnchor>
                          <Box pos="absolute" bottom="0" left="0" w="full" h="full" zIndex="-1"></Box>
                        </PopoverAnchor>
                      )} */}
                      {hasOptions && (
                        <Text
                          ml="1"
                          color="gray.800"
                          lineHeight="26px"
                          borderRadius="full"
                          borderWidth="thin"
                          borderColor={
                            hasOptions && !isActive(chunk)
                              ? "#ededed"
                              : isSelected
                                ? "white"
                                : hasOptions
                                  ? "primary.400"
                                  : ""
                          }
                          fontWeight="medium"
                          fontSize="sm"
                          px="2"
                          _hover={{ bgColor: "primary.200", borderColor: "primary.400" }}
                          bgColor={
                            hasOptions && !isActive(chunk) ? "#ededed" : isSelected ? "white" : hasOptions ? "auto" : ""
                          }
                          display="inline"
                        >
                          {valueText(chunk, productInfoData)}
                        </Text>
                      )}
                    </Box>
                  </PopoverTrigger>
                </Fragment>
              );
            })}

            {hasEdiMedia && (
              <>
                <Divider mt="2" />
                <Flex fontWeight="semibold" alignItems="center">
                  EDI file：
                  {ediMedia.map((elem) => (
                    <IconButton
                      key={elem.id}
                      as="a"
                      href={elem?.url}
                      target="_blank"
                      variant="unstyle"
                      size="xs"
                      aria-label={`EDI file ${elem.id}`}
                      rounded="100%"
                      icon={<Icon as={FaRegFileAlt} boxSize="4" color="gray.400" _hover={{ color: "gray.800" }} />}
                    />
                  ))}
                </Flex>
              </>
            )}
          </Box>
          {!isSelf && <Spacer />}
          {isSelf && <Avatar name={participant?.displayName || author} size="sm" />}
        </HStack>
      )}
    </VStack>
  );
}

const WavePlayer = ({
  trackUrl,
  segments,
  setActiveCaptionIndex,
}: {
  trackUrl: string;
  segments: { id: number; start: number; end: number; text: string }[];
  setActiveCaptionIndex: Dispatch<SetStateAction<number | null>>;
}) => {
  const containerRef = useRef(null);
  const {
    isOpen: isMenuOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose,
    onToggle: onMenuToggle,
  } = useDisclosure({
    defaultIsOpen: true,
  });
  const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
    container: containerRef,
    height: 60,
    waveColor: "#85b0fc",
    progressColor: "#ACA9B2",
    url: trackUrl,
    plugins: useMemo(() => [Timeline.create()], []),
  });
  const onPlayPause = useCallback(() => {
    wavesurfer && wavesurfer.playPause();
  }, [wavesurfer]);

  const onSetPlaybackRate = useCallback(
    (rate: number) => {
      wavesurfer && wavesurfer.setPlaybackRate(rate);
    },
    [wavesurfer]
  );

  const playbackRates = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on("audioprocess", () => {
        const currentTime = wavesurfer.getCurrentTime();
        const activeIndex = segments.findIndex((c) => c.start <= currentTime && c.end >= currentTime);
        setActiveCaptionIndex(activeIndex);
      });
    }
  }, [wavesurfer]);

  const formatTime = (seconds: number) =>
    [seconds / 60, seconds % 60].map((v) => `0${Math.floor(v)}`.slice(-2)).join(":");

  const [speed, setSpeed] = useState("1");

  return (
    <>
      <HStack>
        <VStack mb="3">
          <IconButton
            onClick={onPlayPause}
            icon={isPlaying ? <Icon as={HiPause} boxSize="8" /> : <Icon as={HiPlay} boxSize="8" />}
            aria-label="Play Voicemail"
            variant="unstyled"
            _hover={{ cursor: "pointer", color: "primary.500" }}
            color="primary.400"
          />
          <Text fontSize="2xs">{formatTime(currentTime)}</Text>
        </VStack>
        <Box ref={containerRef} w="full" h="24" _hover={{ cursor: "pointer" }} />
      </HStack>
      <HStack>
        <Text fontSize="2xs">Play Speed</Text>
        <Menu closeOnSelect={false}>
          <MenuButton
            as={Button}
            variant="unstyled"
            _hover={{ color: "gray.800" }}
            color="gray.800"
            _focusVisible={{ outline: "none" }}
            display={isMenuOpen ? "inline" : "none"}
          >
            <HStack spacing="1">
              <Text fontSize="xs">{speed}</Text>
              <Icon as={HiChevronDown} boxSize="3" />
            </HStack>
          </MenuButton>
          <MenuList zIndex={10} minW="16" mt="-3" ml="-6">
            <MenuOptionGroup
              title=""
              defaultValue="1"
              type="radio"
              mt="0"
              p="1"
              onChange={(val) => {
                onSetPlaybackRate(Number(val));
                setSpeed(val as string);
              }}
            >
              {playbackRates.map((elem, index) => (
                <MenuItemOption
                  value={elem.toString()}
                  fontSize="2xs"
                  _hover={{ bgColor: "gray.50", color: "gray.800" }}
                  _focus={{ bgColor: "gray.50", color: "gray.800" }}
                  key={index}
                  _checked={{ color: "gray.800", fontWeight: "semibold" }}
                  icon={<></>}
                >
                  {formatTitle(elem.toString())}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </HStack>
    </>
  );
};

export function createChunks(data: Message): Chunk[] {
  const chunks: Chunk[] = [];
  let list: Suggestion[] = data.mediaList
    ?.find((elem) => elem.type === "Audio")
    ?.segments?.map((elem, index) => ({
      textRange: elem.textRange,
      segmentIndex: index,
    })) ?? [{ textRange: [0, data.body.length] }];

  data.suggestions?.forEach((insertItem) => {
    const [start, end] = insertItem.textRange;
    if (start < 0) return;
    list = list.reduce((prev: Suggestion[], curr) => {
      const [from, to] = curr.textRange;
      if (start > to - 1 || end < from + 1) {
        return prev.concat([curr]);
      } else {
        if (from < start) {
          prev.push({
            textRange: [from, start],
            segmentIndex: curr.segmentIndex,
          });
        }
        if (!prev.some((elem) => _.isEqual(elem.textRange, [start, end]))) {
          prev.push({
            ...insertItem,
            textRange: [start, end],
            segmentIndex: curr.segmentIndex,
          });
        }
        if (to > end) {
          prev.push({
            textRange: [end, to],
            segmentIndex: curr.segmentIndex,
          });
        }
        return prev;
      }
    }, []);
  });
  list?.forEach((item, index: number) => {
    const [start, end] = item.textRange;
    if (start < 0) return chunks;

    if (item?.lineItemSuggestions) {
      const suggestionItem = data.suggestions?.find((elem) => _.isEqual(elem.textRange, item.textRange));
      const poi = (suggestionItem?.chunk as Chunk) ?? {
        options:
          item?.lineItemSuggestions?.map((sug) => {
            return {
              slug: sug.slug,
              productId: sug.productId,
              quantity: Number(sug.quantity),
              description: sug.description,
              lastOrderedDate: sug.lastOrderedDate,
              text: `${sug.quantity} ${sug.description}`,
            } as ChunkOption;
          }) ?? [],
        text: substr(data.body, start, end - start),
        textRange: item.textRange,
        isManual: item.isManual,
        isAdded: item.isAdded,
        manual: {},
        focus: 0,
        selected: 0,
        segmentIndex: item.segmentIndex,
      };
      if (suggestionItem) {
        suggestionItem.chunk = poi;
      }
      item.chunk = poi;
      chunks.push(poi);
    } else {
      chunks.push({
        text: substr(data.body, start, end - start),
        textRange: [start, end],
        segmentIndex: item.segmentIndex,
      });
    }
  });
  return chunks;
}

export function isActive(c: Chunk) {
  if (c.isManual) {
    return c.manual?.quantity && c.manual?.productId;
  } else {
    return c.options && c.selected !== undefined;
  }
}

function valueText(c: Chunk, productInfoData: ProductInfoMap) {
  if (c.isManual) {
    return c?.manual?.slug ?? c?.manual?.productId;
  }
  if (c.selected === undefined) {
    return "-";
  }
  const productId = c.options?.[c.selected]?.slug ?? c.options?.[c.selected]?.productId;
  return productId || "-";
}

function getProductByChunk(c: Chunk) {
  if (c.isManual) {
    return c.manual;
  } else if (c.options && c.selected !== undefined) {
    return c.options?.[c.selected];
  }
  return;
}

export function genLineItemWithRecords(messages: Message[]) {
  const list: LineItemWithRecords[] = [];
  messages?.forEach((msg, x) => {
    msg?.chunks?.forEach((c, y) => {
      if (isActive(c)) {
        const product = getProductByChunk(c);
        const slug = product?.slug;
        const productId = product?.productId!;
        const description = product?.description!;
        const lastOrderedDate = product?.lastOrderedDate;
        const quantity = Number(product?.quantity!);
        const old = list.find((elem) => elem.productId === product?.productId);
        switch (msg.action) {
          case "CreateOrder":
            list.push({
              productId,
              description,
              quantity,
              lastOrderedDate,
              records: [
                {
                  x,
                  y,
                  action: msg.action,
                  change: quantity,
                  diff: `+${quantity}`,
                  text: c.text,
                },
              ],
            });
            break;
          case "AddItem":
            if (old) {
              old.quantity += quantity;
              old.records.push({
                x,
                y,
                action: msg.action,
                change: quantity,
                diff: `+${quantity}`,
                text: c.text,
              });
            } else {
              list.push({
                productId,
                description,
                quantity,
                lastOrderedDate,
                records: [
                  {
                    x,
                    y,
                    action: msg.action,
                    change: quantity,
                    diff: `+${quantity}`,
                    text: c.text,
                  },
                ],
              });
            }
            break;
          case "UpdateQty":
            if (old) {
              old.quantity = quantity;
              old.records.push({
                x,
                y,
                action: msg.action,
                change: quantity,
                diff: `${quantity >= old.quantity ? "+" : ""}${quantity - old.quantity}`,
                text: c.text,
              });
            } else {
              list.push({
                productId,
                description,
                quantity,
                lastOrderedDate,
                records: [
                  {
                    x,
                    y,
                    action: msg.action,
                    change: quantity,
                    diff: `+${quantity}`,
                    text: c.text,
                  },
                ],
              });
            }
            break;
          case "DeleteItem":
            if (old) {
              old.quantity -= quantity;
              old.records.push({
                x,
                y,
                action: msg.action,
                change: quantity,
                diff: `-${quantity}`,
                text: c.text,
              });
            }
            break;
          default:
            break;
        }
      }
    });
  });
  return list.map((elem) => ({ ...elem, quantity: Number(elem.quantity) }));
}

const MessageFailed = (props: { creationError: string }) => <Text color="red.500">{props.creationError}</Text>;

const MessageFailedCase = (props: { creationStatus: string; creationError?: string }) =>
  props.creationStatus === "Failed" && props.creationError && <MessageFailed creationError={props.creationError} />;
